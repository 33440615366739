import React from 'react';
import {Routes, Route, NavLink} from 'react-router-dom';
import './App.css';
import 'react-awesome-slider/dist/styles.css';
import logo from './naidugaru.svg';
import Home from './components/home';
import News from './components/news';
import Shop from './components/shop';
import Join from './components/join';

function App() {
  return (
    <div className="App">
        <header className="App-header">

            <div className='container'>
                <div className='logo'>
                    <NavLink to="/">
                        <img src={logo} className="naidugaru-logo" alt="Naidugaru" title='Naidugaru' />
                        <h3>నాయుడు గారు</h3>
                    </NavLink>  
                </div>
                
                <div className='menu'>
                    <ul>
                        <li><a href="/"><i class="fa-solid fa-house"></i> Home</a></li>                       
                        <li><a href="/shop"><i class="fa-solid fa-cart-shopping"></i> Shop</a></li>
                        <li><a href="/news"><i class="fas fa-file-alt"></i> News</a></li>
                        <li><a href="/join" className="join-link"><i class="fas fa-user"></i> Join us</a></li>
                    </ul>
                </div>

                <div className='mobile-menu'>
                    <div class="navBtn">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <ul>
                        <li><a href="/">Home</a></li>                       
                        <li><a href="/shop">Shop</a></li>
                        <li><a href="/news">News</a></li>
                        <li><a href="/join" className="join-link">Join us</a></li>
                    </ul>
                </div>
            </div>

        </header>

        <Routes>
            <Route index element={<Home />} />
            <Route path="/news" element={<News />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/join" element={<Join />} />
        </Routes>

        <footer>
            <div className="container">
                <div className="logo">
                    <a href="/">
                        <img src={logo} className="naidugaru-logo" alt="Naidugaru" title='Naidugaru' />
                        <h3>నాయుడు గారు</h3>
                    </a>
                </div>
                <div className="social">
                    <ul>
                        <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                        <li><a href="#"><i class="fa-brands fa-instagram"></i></a></li>
                        <li><a href="#"><i class="fa-brands fa-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </footer>

    </div>
  );
}

export default App;