import React, { Component } from 'react';

export default function() {
        return(
            <div className="App">

                <div className="news">
                    <div class="container">
                        <h2>News</h2>
                        <div className="article">
                            <figure>
                                <img src={require('../images/news/pawan-kalyan.jpg')} alt="Naidugaru news" title="Naidugaru news" />              
                                <figcaption>
                                    <h3>On Pawan Kalyan's Birthday, Jana Sena Party Organises Multiple Charity Events</h3>
                                    <h5>by : Siddharth Naidu | Uploaded on 1st September 2023</h5>
                                    <p>Actor-cum-politician Pawan Kalyan is celebrating his 52nd birthday today with much pomp and grandeur. The Jana Sena Party chief is widely revered all over the two Telugu-speaking states and on this special occasion, his party is carrying out many services and social programs.</p>
                                    <p><a href="https://www.news18.com/movies/on-pawan-kalyans-birthday-jana-sena-party-organises-multiple-charity-events-8560985.html" target='_blank'>Read more</a></p>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="article">
                            <figure>
                                <img src={require('../images/news/pawan-kalyan-janasena.jpg')} alt="Naidugaru news" title="Naidugaru news" />                   
                                <figcaption>
                                    <h3>JSP chief pays tributes to Gidugu, exhorts people to protect Telugu</h3>
                                    <h5>by : Siddharth Naidu | Uploaded on 30th August 2023</h5>
                                    <p>Greeting people of Andhra Pradesh on the occasion of the Telugu Language Day, Jana Sena Party chief Pawan Kalyan called on people of the State to conserve and protect the mother tongue, while alleging that the YSRC government had no interest in preserving it.</p>
                                    <p><a href="https://www.newindianexpress.com/states/andhra-pradesh/2023/aug/30/jsp-chief-pays-tributes-to-gidugu-exhorts-people-to-protect-telugu-2609881.html" target='_blank'>Read more</a></p>
                                </figcaption>
                            </figure>    
                        </div>
                        <div className="article">
                            <figure>
                                <img src={require('../images/news/pawan-kalyan-with-national-flag.jpg')} alt="Naidugaru news" title="Naidugaru news" />                   
                                <figcaption>
                                    <h3>Pawan Kalyan-led Jana Sena gets ready for marathon rally</h3>
                                    <h5>by : Siddharth Naidu | Uploaded on 15th October 2018</h5>
                                    <p>VIJAYAWADA: The Jana Sena is getting ready for October 15 marathon rally across Godavari river on the histrocial Dowleswara Bridge.</p>
                                    <p><a href="https://timesofindia.indiatimes.com/city/vijayawada/vijayawada-jana-sena-gets-ready-for-marathon-rally-across-godavari/articleshow/66213587.cms" target='_blank'>Read more</a></p>
                                </figcaption>
                            </figure>    
                        </div>                        
                    </div>
                </div>

                <div className="janasena">
                    <div class="container">
                        <h2>We Support Janasena Party</h2>
                        <p>Join today Janasena party to vote Sri Konidela Pawan Kalyan garu to make change in upcoming Andhra Pradesh elections.</p>
                        <p><a href="https://janasenaparty.org/membership" target='_blank'>Join Janasena</a></p>
                    </div>
                </div>


            </div>
        )
}