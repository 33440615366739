import React, { Component } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import ScrollCarousel from 'scroll-carousel-react';
import InfiniteCarousel from 'react-leaf-carousel';
import Typewriter from 'typewriter-effect';

export default function() {
        return(
            <div className="App">

                <div className="banner">
                    <AwesomeSlider>

                        <div className='slide email-banner'>
                            <img src={require('../images/naidugaru-bg.jpg')} alt="Naidugaru email benefits" title="Naidugaru email benefits" />
                            <div className='slide-info'>
                                <div className='container'>
                                    <figure>
                                        <img src={require('../images/email-benefits.png')} alt="Email Naidugaru email benefits" title="Naidugaru email benefits" />
                                        <figcaption>
                                            <h2>Welcome <br /><span>నాయుడు గారు...</span></h2>
                                            <p>Join today & Get your personal gmail account with your name<br />
                                                <Typewriter
                                                    options={{
                                                        strings: ['yourname', 'Siddharth', 'Hemanth', 'Anil', 'Ajay', 'Ganesh', 'Ragavendra', 'Chanti', 'Mahesh'],
                                                        autoStart: true,
                                                        loop: true,
                                                        pauseFor: 3000,
                                                    }}
                                                />
                                                <span>@naidugaru.com</span> starting from 30gb cloud storage</p>
                                            <p><a href="#">Join now</a></p>
                                        </figcaption>
                                    </figure>                                                     
                                </div>
                            </div>                   
                        </div>

                        <div className='slide amaravathi-banner'>
                            <img src={require('../images/naidugaru-bg.jpg')} alt="Capital Amaravathi" title="Capital Amaravathi" />
                            <div className='slide-info'>
                                <div className='container'>
                                    <figure>
                                        <img src={require('../images/amaravathi.png')} alt="Capital Amaravathi" title="Capital Amaravathi" />
                                        <figcaption>
                                            <h2><span>అమరావతి ఒక్కటే మన రాజధాని</span></h2>
                                            <p>The land with great history & magnificent advantages.</p>
                                            <p><a href="https://en.wikipedia.org/wiki/Amaravati" target='_blank'>Read more</a></p>
                                        </figcaption>
                                    </figure>                                                     
                                </div>
                            </div>                   
                        </div>

                        <div className='slide janasena-banner'>
                            <img src={require('../images/naidugaru-bg.jpg')} alt="Join Janasena" title="Join Janasena" />
                            <div className='slide-info'>
                                <div className='container'>
                                    <figure>
                                        <img src={require('../images/janasena.png')} alt="Join Janasena" title="Join Janasena" />
                                        <figcaption>
                                            <h2>We Support Janasena party</h2>
                                            <p>Vote Sri Konidela Pawan Kalyan garu to make change in upcoming Andhra Pradesh state elections.</p>
                                            <p><a href="https://janasenaparty.org/membership" target='_blank'>Join Janasena</a></p>
                                        </figcaption>
                                    </figure>                                                     
                                </div>
                            </div>                   
                        </div>

                    </AwesomeSlider>
                </div>

                <div className="initiative">
                    <div class="container">
                        <h3>About us</h3>
                        <h2>An outstanding determination to <span>bring everyone in our social class around the world under one roof "our naidugaru"</span></h2>
                    </div>
                </div>

                <div className="whatsapp-group">
                    <div class="container">
                        <div className="whatsapp-group-info">
                            <h3>Naidugaru Whatsapp Group</h3>
                            <h2><span>Chat & Promote your business on naidugaru community</span></h2>
                            <p><a href="https://chat.whatsapp.com/Lgyyege7DT2FTm41KRFZFU" target="_blank">Join using link</a></p>
                        </div>
                        <div className="whatsapp-group-graphic">    
                            <img src={require('../images/naidugaru-whatsapp-group.png')} alt="Naidugaru Whatsapp Group" title="Naidugaru Whatsapp Group" />
                        </div>
                    </div>
                </div>

                <div className="janasena">
                    <div class="container">
                        <h2>We Support Janasena Party</h2>
                        <p>Join today Janasena party to vote Sri Konidela Pawan Kalyan garu to make change in upcoming Andhra Pradesh elections.</p>
                        <p><a href="https://janasenaparty.org/membership" target='_blank'>Join Janasena</a></p>
                    </div>
                </div>

                <div className="shop-slider">
                    <div class="container">
                        <h2>Shop</h2>

                        <InfiniteCarousel
                            breakpoints={[
                            {
                                breakpoint: 700,
                                settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                },
                            },
                            {
                                breakpoint: 1100,
                                settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                },
                            },
                            ]}
                                dots={true}
                                showSides={false}
                                sidesOpacity={.5}
                                sideSize={.1}
                                slidesToScroll={4}
                                slidesToShow={4}
                                scrollOnDevice={true}
                            >
                            <div><img src={require('../images/products/t-shirt.jpg')} alt="Naidugaru products tshirt" title="Naidugaru products tshirt" /></div>
                            <div><img src={require('../images/products/woman-tshirt.jpg')} alt="Naidugaru products woman tshirt" title="Naidugaru products woman tshirt" /></div>
                            <div><img src={require('../images/products/cap.jpg')} alt="Naidugaru products Cap" title="Naidugaru products Cap" /></div>
                            <div><img src={require('../images/products/cup.jpg')} alt="Naidugaru products Cup" title="Naidugaru products Cup" /></div>
                            <div><img src={require('../images/products/coffee-cup.jpg')} alt="Naidugaru products Coffee Cup" title="Naidugaru products Coffee Cup" /></div>
                            <div><img src={require('../images/products/bike-sticker.jpg')} alt="Naidugaru products Bike Sticker" title="Naidugaru products Bike Sticker" /></div>
                            <div><img src={require('../images/products/android-phone-case.jpg')} alt="Naidugaru products Android Phone Case" title="Naidugaru products Android Phone Case" /></div>
                            <div><img src={require('../images/products/logo-sticker-white.jpg')} alt="Naidugaru products sticker" title="Naidugaru products sticker" /></div>
                            <div><img src={require('../images/products/logo-sticker.jpg')} alt="Naidugaru products sticker" title="Naidugaru products sticker" /></div>
                            <div><img src={require('../images/products/phone-case-dark.jpg')} alt="Naidugaru products Phone Case Dark" title="Naidugaru products Phone Case Dark" /></div>
                            <div><img src={require('../images/products/phone-case-light.jpg')} alt="Naidugaru products Phone Case Light" title="Naidugaru products Phone Case Light" /></div>                            
                            <div><img src={require('../images/products/wallet.jpg')} alt="Naidugaru products wallet" title="Naidugaru products wallet" /></div>
                            <div><img src={require('../images/products/phone-case.jpg')} alt="Naidugaru products Phone Case" title="Naidugaru products Phone Case" /></div>
                            <div><img src={require('../images/products/blue-tshirt.jpg')} alt="Naidugaru products blue tshirt" title="Naidugaru products blue tshirt" /></div>
                        </InfiniteCarousel>

                    </div>
                </div>

                <div className="news">
                    <div class="container">
                        <h2>News</h2>
                        <div className="article">
                            <figure>
                                <img src={require('../images/news/pawan-kalyan.jpg')} alt="Naidugaru news" title="Naidugaru news" />              
                                <figcaption>
                                    <h3>On Pawan Kalyan's Birthday, Jana Sena Party Organises Multiple Charity Events</h3>
                                    <h5>by : Siddharth Naidu | Uploaded on 1st September 2023</h5>
                                    <p>Actor-cum-politician Pawan Kalyan is celebrating his 52nd birthday today with much pomp and grandeur. The Jana Sena Party chief is widely revered all over the two Telugu-speaking states and on this special occasion, his party is carrying out many services and social programs.</p>
                                    <p><a href="https://www.news18.com/movies/on-pawan-kalyans-birthday-jana-sena-party-organises-multiple-charity-events-8560985.html" target='_blank'>Read more</a></p>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="article">
                            <figure>
                                <img src={require('../images/news/pawan-kalyan-janasena.jpg')} alt="Naidugaru news" title="Naidugaru news" />                   
                                <figcaption>
                                    <h3>JSP chief pays tributes to Gidugu, exhorts people to protect Telugu</h3>
                                    <h5>by : Siddharth Naidu | Uploaded on 30th August 2023</h5>
                                    <p>Greeting people of Andhra Pradesh on the occasion of the Telugu Language Day, Jana Sena Party chief Pawan Kalyan called on people of the State to conserve and protect the mother tongue, while alleging that the YSRC government had no interest in preserving it.</p>
                                    <p><a href="https://www.newindianexpress.com/states/andhra-pradesh/2023/aug/30/jsp-chief-pays-tributes-to-gidugu-exhorts-people-to-protect-telugu-2609881.html" target='_blank'>Read more</a></p>
                                </figcaption>
                            </figure>    
                        </div>
                    </div>
                </div>

                <div className="members">
                    <ScrollCarousel
                        autoplay={true}
                        autoplaySpeed={15}
                        items={6}
                        speed={7}
                        onReady={() => console.log('I am ready')}
                        >
                        {[                   
                            <img src={require('../images/naidus/chanti.jpg')} />,
                            <img src={require('../images/naidus/ganesh.jpg')} />,
                            <img src={require('../images/naidus/hemanth.jpg')} />,
                            <img src={require('../images/naidus/pavan.jpg')} />,
                            <img src={require('../images/naidus/satish.jpg')} />,
                            <img src={require('../images/naidus/ajay.jpg')} />,
                            <img src={require('../images/naidus/babu.jpg')} />,
                            <img src={require('../images/naidus/siddharth.jpg')} />,
                            <img src={require('../images/naidus/yagnikanth.jpg')} />,
                        ].map((item) => (
                        <div key={item} className='border-0'>
                            {item}
                        </div>
                        ))}
                    </ScrollCarousel>
                </div>

            </div>
        )
}