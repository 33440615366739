import React, { Component } from 'react';
import ScrollCarousel from 'scroll-carousel-react';

export default function() {
        return(
            <div className="App">

                <div className="initiative">
                    <div class="container">
                        <h3>Join us</h3>
                        <h2>An outstanding determination to <span>bring everyone in our social class around the world under one roof "our naidugaru"</span></h2>
                    </div>
                </div>

                <div className="whatsapp-group">
                    <div class="container">
                        <div className="whatsapp-group-info">
                            <h3>Join using Naidugaru Whatsapp Group link below</h3>
                            <h2><span>Chat & Promote your business on naidugaru community</span></h2>
                            <p><a href="https://chat.whatsapp.com/Lgyyege7DT2FTm41KRFZFU" target="_blank">Join using link</a></p>
                        </div>
                        <div className="whatsapp-group-graphic">    
                            <img src={require('../images/naidugaru-whatsapp-group.png')} alt="Naidugaru Whatsapp Group" title="Naidugaru Whatsapp Group" />
                        </div>
                    </div>
                </div>

                <div className="members">
                    <ScrollCarousel
                        autoplay={true}
                        autoplaySpeed={15}
                        items={6}
                        speed={7}
                        onReady={() => console.log('I am ready')}
                        >
                        {[                   
                            <img src={require('../images/naidus/chanti.jpg')} />,
                            <img src={require('../images/naidus/ganesh.jpg')} />,
                            <img src={require('../images/naidus/hemanth.jpg')} />,
                            <img src={require('../images/naidus/pavan.jpg')} />,
                            <img src={require('../images/naidus/satish.jpg')} />,
                            <img src={require('../images/naidus/ajay.jpg')} />,
                            <img src={require('../images/naidus/babu.jpg')} />,
                            <img src={require('../images/naidus/siddharth.jpg')} />,
                            <img src={require('../images/naidus/yagnikanth.jpg')} />,
                        ].map((item) => (
                        <div key={item} className='border-0'>
                            {item}
                        </div>
                        ))}
                    </ScrollCarousel>
                </div>

            </div>
        )
}