import React, { Component } from 'react';
import 'react-awesome-slider/dist/styles.css';

export default function() {
        return(
            <div className="App">

                <div className="shop">
                    <div class="container">
                        <h2>Shop</h2>

                        <div className='product'><img src={require('../images/products/t-shirt.jpg')} alt="Naidugaru products tshirt" title="Naidugaru products tshirt" /></div>
                        <div className='product'><img src={require('../images/products/woman-tshirt.jpg')} alt="Naidugaru products woman tshirt" title="Naidugaru products woman tshirt" /></div>
                        <div className='product'><img src={require('../images/products/cap.jpg')} alt="Naidugaru products Cap" title="Naidugaru products Cap" /></div>
                        <div className='product'><img src={require('../images/products/blue-tshirt.jpg')} alt="Naidugaru products blue tshirt" title="Naidugaru products blue tshirt" /></div>
                        <div className='product'><img src={require('../images/products/cup.jpg')} alt="Naidugaru products Cup" title="Naidugaru products Cup" /></div>
                        <div className='product'><img src={require('../images/products/coffee-cup.jpg')} alt="Naidugaru products Coffee Cup" title="Naidugaru products Coffee Cup" /></div>
                        <div className='product'><img src={require('../images/products/bike-sticker.jpg')} alt="Naidugaru products Bike Sticker" title="Naidugaru products Bike Sticker" /></div>
                        <div className='product'><img src={require('../images/products/android-phone-case.jpg')} alt="Naidugaru products Android Phone Case" title="Naidugaru products Android Phone Case" /></div>
                        <div className='product'><img src={require('../images/products/logo-sticker-white.jpg')} alt="Naidugaru products sticker" title="Naidugaru products sticker" /></div>
                        <div className='product'><img src={require('../images/products/logo-sticker.jpg')} alt="Naidugaru products sticker" title="Naidugaru products sticker" /></div>
                        <div className='product'><img src={require('../images/products/phone-case-dark.jpg')} alt="Naidugaru products Phone Case Dark" title="Naidugaru products Phone Case Dark" /></div>
                        <div className='product'><img src={require('../images/products/phone-case-light.jpg')} alt="Naidugaru products Phone Case Light" title="Naidugaru products Phone Case Light" /></div>                            
                        <div className='product'><img src={require('../images/products/wallet.jpg')} alt="Naidugaru products wallet" title="Naidugaru products wallet" /></div>
                        <div className='product'><img src={require('../images/products/phone-case.jpg')} alt="Naidugaru products Phone Case" title="Naidugaru products Phone Case" /></div>                        

                    </div>
                </div>

                <div className="whatsapp-group">
                    <div class="container">
                        <div className="whatsapp-group-info">
                            <h3>Naidugaru Whatsapp Group</h3>
                            <h2><span>Chat & Promote your business on naidugaru community</span></h2>
                            <p><a href="https://chat.whatsapp.com/Lgyyege7DT2FTm41KRFZFU" target="_blank">Join using link</a></p>
                        </div>
                        <div className="whatsapp-group-graphic">    
                            <img src={require('../images/naidugaru-whatsapp-group.png')} alt="Naidugaru Whatsapp Group" title="Naidugaru Whatsapp Group" />
                        </div>
                    </div>
                </div>

                <div className="news">
                    <div class="container">
                        <h2>News</h2>
                        <div className="article">
                            <figure>
                                <img src={require('../images/news/pawan-kalyan.jpg')} alt="Naidugaru news" title="Naidugaru news" />              
                                <figcaption>
                                    <h3>On Pawan Kalyan's Birthday, Jana Sena Party Organises Multiple Charity Events</h3>
                                    <p>Actor-cum-politician Pawan Kalyan is celebrating his 52nd birthday today with much pomp and grandeur. The Jana Sena Party chief is widely revered all over the two Telugu-speaking states and on this special occasion, his party is carrying out many services and social programs.</p>
                                    <p><a href="#">Read more</a></p>
                                </figcaption>
                            </figure>
                        </div>
                        <div className="article">
                            <figure>
                                <img src={require('../images/news/pawan-kalyan-with-national-flag.jpg')} alt="Naidugaru news" title="Naidugaru news" />                   
                                <figcaption>
                                    <h3>Pawan Kalyan-led Jana Sena gets ready for marathon rally</h3>
                                    <p>VIJAYAWADA: The Jana Sena is getting ready for October 15 marathon rally across Godavari river on the histrocial Dowleswara Bridge.</p>
                                    <p><a href="#">Read more</a></p>
                                </figcaption>
                            </figure>    
                        </div>
                    </div>
                </div>

            </div>
        )
}